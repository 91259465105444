<template>
  <div>
    <h5 class="font-weight-light pb-3">Gestión de usuarios</h5>

    <v-card class="elevation-2" id="cardborde">
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="verde"
      ></v-progress-linear>
      <v-card-title class="font-weight-light">
        Lista de Usuarios
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          color="primary"
          label="Buscar"
          hide-details
          class="m-2"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          color="verde lighten-"
          class="mb-2  primary--text"
          @click="$router.push({ name: 'usuarioid' })"
          >Nuevo Usuario</v-btn
        >
      </v-card-title>
      <v-tabs right>
        <v-tab @click="bEstado = true">Activos</v-tab>
        <v-tab @click="bEstado = false">Inactivos</v-tab>
      </v-tabs>
      <v-data-table
        class="mt-3"
        :headers="headers"
        :items="filteredItems"
        :no-results-text="noresult"
        no-data-text="Sin resultados que mostrar"
        :search="search"
        :custom-filter="filterOnlyCapsText"
        :footer-props="{
          'items-per-page-text': 'Resultados por página',
          'items-per-page-all-text': 'Todos',
        }"
      >
        <template #footer.page-text="props">
          {{ props.pageStart }}-{{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:item.url="{ item }">
          <v-icon small class="mr-2" @click="verURL(item.url)"> mdi-web </v-icon>
        </template>
        <template v-slot:item.accion="{ item }">
        
          <v-chip
            class="mr-2 p-1"
            color="primary"
            text-color="verde"
            small
          >
            <v-icon
              small
              class="m-0 verde--text"
              @click="editItem(item._id)"
            >
              mdi-pencil
            </v-icon>
          </v-chip>

          <v-chip
            class="ml-0 p-1"
            color="primary"
            text-color="verde"
            small
          >
            <v-icon
              small
              v-if="usuarioDB.data.role == 'ADMIN'"
              class="red--text p-0"
              @click="deleteItem(item._id)"
            >
              mdi-delete
            </v-icon>
          </v-chip>
        
        </template>

        <template v-slot:item.activo="{ item }">
          <!--<v-chip :color="getEstados(item.estado)" dark outlined>{{ getEstadosTexto(item.estado) }}</v-chip>-->
          <v-icon :color="getEstados(item.activo)" dark outlined>
            mdi-{{ getEstadosTexto(item.activo) }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-snackbar v-model="snackbar" color="green" dark timeout="1500" align="center">
      URL copiada exitosamente!
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

//Definimos la clase de los elementos

export default {
  data() {
    return {
      loading: true,
      noresult: "No existen resultados",
      search: "",
      dialogVerURL: false,
      urlSitio: "",
      snackbar: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Cargo",
          value: "cargo",
        },
        {
          text: "Estado",
          value: "activo",
        },
        {
          text: "ROL",
          value: "role",
          sortable: false,
        },
        {
          text: "Accion",
          value: "accion",
          sortable: "false",
        },
      ],
      desserts: [],

      bEstado: true,
    };
  },

  computed: {
    ...mapState(["token", "notificationSystem", "usuarioDB"]),
    filteredItems() {
      return this.desserts.filter((i) => {
        return i.activo === this.bEstado;
      });
    },
  },
  methods: {
    editItem(id) {
      this.$router.push({
        name: "usuarioid",
        params: {
          id: id,
        },
      });
    },
    getData() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      axios.get("/users/user/", config).then((response) => {
        this.desserts = response.data;
        this.loading = false;
      });
    },
    async deleteItem(item) {
      this.loading = true;
      let config = {
        headers: {
          token: this.token,
        },
      };

      await axios
        .delete("/users/user/" + item, config)
        .then((response) => {
          this.getData();
          this.$toast.success(
            "Eliminado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });
    },
    getEstados(estado) {
      if (estado == true) return "green";
      else if (estado == false) return "red";
      else return "grey";
    },

    getEstadosTexto(estado) {
      if (estado == true) return "check";
      else if (estado == false) return "close";
      else return "N/A";
    },

    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style>
th span {
  color: "#FF0000" !important;
  border: 0px solid #7181b8;
  border-radius: 44px;
  padding: 5px;
  background: #e8eaf6;
}
#cardborde {
  border-top: 5px solid #e8eaf6 !important;
}
</style>
